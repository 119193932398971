<template>
    <div>
        <b-card no-body>
            <b-card-body>
                <div class="d-flex justify-content-end  flex-wrap">

                    <!-- filter -->
                    <b-form-group

                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput"
                                v-model="pagination.filter"
                                type="search"
                                :placeholder="$t('Type to Search')"
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!pagination.filter"
                                    @click="pagination.filter = ''"
                                >
                                    {{ $t("Clear") }}
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </div>
            </b-card-body>

            <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="pagination.perPage"
                :current-page="pagination.currentPage"
                :items="satisfaction"
                :fields="fields"
                :filter="pagination.filter"
                @row-clicked="navigateToSatisfaction"
            >
                <template #cell(avatar)="data">
                    <b-avatar :src="data.item.files[0].full_path"/>
                </template>
                <template #cell(userName)="data">
                    <p>{{ data.item.user.first_name }} {{ data.item.user.last_name }}</p>
                </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                <!-- page length -->
                <b-form-group
                    :label="$t('Per Page')"
                    label-cols="8"
                    label-align="left"
                    label-size="md"
                    label-for="perPageSelect"
                    class="text-nowrap mb-md-0 mr-1"
                >
                    <b-form-select
                        @change="updatePerPage"
                        id="perPageSelect"
                        v-model="pagination.perPage"
                        size="sm"
                        inline
                        :options="pagination.pageOptions"
                    />
                </b-form-group>

                <!-- pagination -->
                <div>
                    <b-pagination
                        v-model="pagination.currentPage"
                        :total-rows="totalRows"
                        :per-page="pagination.perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mb-0"
                        @change="updatePerPage"
                    >
                        <template #prev-text>
                            <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import {
    BAvatar,
    BBadge, BButton,
    BCard, BCardBody,
    BFormCheckbox,
    BFormGroup, BFormInput,
    BFormSelect,
    BInputGroup, BInputGroupAppend,
    BPagination,
    BTable
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    name: "list",
    components: {
        BFormCheckbox,
        BCard,
        BCardCode,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
    },
    data() {
        return {
            pagination: {
                perPage: 6,
                pageOptions: [6, 12, 18],
                currentPage: 1,
                filter: null,
                filterOn: [],
            },
            fields: [
                {key: 'avatar', label: this.$t('Image'),},
                {key: 'title', label: this.$t('title'), sortable: false},
                {key: 'short_description', label: this.$t('Description'), sortable: false},
                {key: 'address', label: this.$t('address'), sortable: false},
                {key: 'userName', label: this.$t('User name'), sortable: false},
                {key: 'created_at', label: this.$t('Created At'), sortable: false},
            ],
            satisfactionOptions: {status: 'memnuniyet', perPage: 6, current_page: 1},
        }
    },
    watch: {
        'pagination.currentPage': function (val) {
            this.satisfactionOptions.current_page = val;
            this.getSatisfactionList();
        }
    },
    computed: {
        satisfaction() {
            return this.$store.state.satisfaction.satisfaction;
        },
        totalRows() {
            return this.$store.state.satisfaction.totalSatisfaction;
        },
    },
    methods: {
        navigateToSatisfaction(row) {
            this.$router.push({name: 'satisfaction-single', params: {id: row.id}});
        },
        resetOptions() {
            this.satisfactionOptions.current_page = 1;
            this.satisfactionOptions.perPage = 6;
            this.satisfactionOptions.status = 'memnuniyet';
        },
        updatePerPage(perPage) {
            this.satisfactionOptions.perPage = perPage;
            this.getSatisfactionList();
        },
        getSatisfactionList() {
            this.$store.dispatch("satisfaction/getSatisfactionList", this.satisfactionOptions)
                .then((res) => {
                })
                .catch((err) => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: err.response.data.message ?? this.$t('Something went wrong'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                });
        },
    },
    created() {
        this.resetOptions();
        this.getSatisfactionList();
    },
}
</script>

<style scoped>

</style>
